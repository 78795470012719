import React from "react";
import {
  Typography,
  Card,
} from "@material-tailwind/react";
// import banner from '../assets/img/Banner-web-no-text.avif';

export function AboutContent() {
  return (
		<div className="bg-backgroundTest-100 mx-auto max-w-screen-xl pb-12 pt-8 px-5">
			{/* <Card className="mb-8 overflow-hidden">
				<img
					alt="nature"
					className="w-full h-[20rem] object-cover object-center"
					src={banner}
				/>
			</Card>		   */}
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					About Me
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<Typography color="white" className="font-light mb-6">
					Throughout my academic journey, I have consistently demonstrated a strong work ethic and dedication
					to learning. My coursework encompassed a wide range of topics, such as creating a graphics renderer
					in C++ and working in a team to produce a finished game. For this, I won an award for best programmer
					at the University of Derby games expo.
				</Typography>
				<Typography color="white" className="font-light mb-2">	
					I have hands-on experience with popular game engines, such as Unity and Unreal Engine and a good understanding
					of the game development pipeline. Additionally, I have experience in the version control tool Git and I was
					responsible for organising my groups repository.
				</Typography>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Education
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<Typography color="white" className="font-light mb-2">
					BSc First-Class Honours in Computer Games Programming - University of Derby (2023)
				</Typography>
		  </Card>
		  <Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Experience
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<Typography variant="h5" color="white" className="font-medium mb-0">
					<a href="https://studio316.co.uk/" target="_blank" rel="noreferrer" className="text-ThreeSixteen-50"> Studio 316</a>
				</Typography>
				<Typography color="white" className="font-light mb-0 leading-3">
					Oct 2023 - Present
				</Typography>
				<Typography color="white" className="font-light mb-0 leading-10"> {/* If changing line height (leading) make mb-2 */}
					Unreal Engine Junior C++ Programmer
				</Typography>
			</Card>
			<Card className="bg-backgroundTest-50 mb-0 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-2">
					Contact
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<Typography color="white" className="font-light mb-2">
					moxomdaniel@gmail.com
				</Typography>
			</Card>
		</div>
  );
}