import React from "react";
import {
  Typography,
  Card,
} from "@material-tailwind/react";

// TODO: Convert from png > avif

import cpp from '../assets/img/Languages/cpp.png';
import csharp from '../assets/img/Languages/c-sharp.png';
import c from '../assets/img/Languages/c.png';
import python from '../assets/img/Languages/python.png';
import js from '../assets/img/Languages/js.png';
import php from '../assets/img/Languages/php.png';
import html from '../assets/img/Languages/HTML5.png';
import css from '../assets/img/Languages/CSS3.png'
import unrealBP from '../assets/img/Languages/unrealengineBP.png'
import sql from '../assets/img/Languages/sql.png';
import unreal from '../assets/img/Engines/unrealengine.png';
import unity from '../assets/img/Engines/unity.png';
import reactIcon from '../assets/img/Frameworks/React.png';
import tailwindIcon from '../assets/img/Frameworks/tailwind.png';
import materialTailwindIcon from '../assets/img/Frameworks/materialtailwind.png';
import directx from '../assets/img/Frameworks/directx.png';
import vscode from '../assets/img/IDEs/vscode.png';
import visualstudio from '../assets/img/IDEs/visualstudio.png';
import pycharm from '../assets/img/IDEs/PyCharm.png';
import github from '../assets/img/Other/github white.svg';
import mssql from '../assets/img/Other/sql-server.png'
import photshop from '../assets/img/Other/photoshop.png';
import illustrator from '../assets/img/Other/illustrator.png';
import premierpro from '../assets/img/Other/premierpro.png';
import blender from '../assets/img/Other/blender.png';
import obs from '../assets/img/Other/obs.png';

export function SkillsContent() {
  return (
		<div className="bg-backgroundTest-100 mx-auto max-w-screen-xl pb-12 pt-8 px-5">
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Programming Languages
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<div className="w-full flex flex-wrap justify-center md:justify-start">
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={cpp} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							C++
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={csharp} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							C#
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={c} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							C
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={python} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Python
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-3 pt-3 pb-0 justify-center items-center">
						<img src={js} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							JavaScript
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-3 pt-3 pb-0 justify-center items-center">
						<img src={php} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							PHP
						</Typography>
					</Card>
				</div>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Games Engines
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<div className="w-full flex flex-wrap justify-center md:justify-start">
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={unreal} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Unreal Engine 5
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={unity} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Unity
						</Typography>
					</Card>
				</div>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Other Languages
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<div className="w-full flex flex-wrap justify-center md:justify-start">
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={unrealBP} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Blueprints
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={sql} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							SQL
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={html} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							HTML
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={css} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							CSS
						</Typography>
					</Card>
				</div>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Frameworks & Libraries
				</Typography>
				<div className="w-full flex flex-wrap justify-center md:justify-start">
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={reactIcon} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							React
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={tailwindIcon} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Tailwind CSS
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={materialTailwindIcon} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Material Tailwind
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={directx} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							DirectX 11
						</Typography>
					</Card>
				</div>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					IDEs
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<div className="w-full flex flex-wrap justify-center md:justify-start">
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={vscode} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							VSCode
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={visualstudio} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Visual Studio
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={pycharm} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							PyCharm
						</Typography>
					</Card>
				</div>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Tools & Other
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<div className="w-full flex flex-wrap justify-center md:justify-start">
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={github} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							GitHub
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={mssql} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							MS SQL Server
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={photshop} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Photoshop
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={illustrator} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Illustrator
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={blender} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Blender
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={obs} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							OBS Studio
						</Typography>
					</Card>
					<Card className="bg-backgroundTest-200 inline-block mb-3 ml-1 mr-2 overflow-hidden px-1 pt-3 pb-0 justify-center items-center">
						<img src={premierpro} alt="" className="h-[8rem] w-[8rem] mb-3"/>
						<Typography className="font-medium text-gray-500 border-t-2 border-gray-500 w-full flex justify-center items-center">
							Premier Pro
						</Typography>
					</Card>
				</div>
			</Card>
		</div>
  );
}