import React from "react";
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';

import { StickyNavbar } from './components/NavBar';

import { AboutContent } from './pages/AboutContent';
import { SkillsContent } from "./pages/SkillsContent";
import { ProjectsContent } from "./pages/ProjectsContent";

function App() {
  return (
    <div className="bg-backgroundTest-100 w-[calc(100%)]">
      <Router>
        <StickyNavbar />
        <Routes>
          <Route exact path='/' element={<AboutContent />} />
          <Route path='/skills' element={<SkillsContent />} />
          <Route path='/projects' element={<ProjectsContent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
