import React from "react";
import {
  Typography,
  Card,
} from "@material-tailwind/react";

export function ProjectsContent() {
  return (
		<div className="bg-backgroundTest-100 mx-auto max-w-screen-xl pb-12 pt-8 px-5">
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Information
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<Typography color="white" className="font-light mb-6">
				  This page is still WIP.
			  </Typography>
			  <Typography color="white" className="font-light mb-6">
					To see currently public projects check out my <a href="https://github.com/DMoxom" target="_blank" rel="noreferrer" className="text-blue-600">Github</a>.
					This website was also created by me with React JS and Tailwind css.
				</Typography>
				<Typography color="white" className="font-light mb-2">	
					This pages development will be much slower as of 30/10/2023 while I work at my new job.
				</Typography>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					University Projects
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<Typography color="white" className="font-light mb-6">
					WIP
				</Typography>
			</Card>
			<Card className="bg-backgroundTest-50 mb-8 overflow-hidden px-5 py-5">
				<Typography variant="h3" color="white" className="font-medium mb-1">
					Personal Projects
				</Typography>
				<Card color="gray" variant="gradient" className="from-PinkYellow-50 to-PinkYellow-100 mb-5 w-[5rem] woverflow-hidden pt-1" />
				<Typography color="white" className="font-light mb-6">
					WIP
				</Typography>
			</Card>	
		</div>
  );
}