import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
	IconButton,
} from "@material-tailwind/react";
import { NavLink } from "react-router-dom";

import linkedin from '../assets/img/linkedin-in.svg';
import github from '../assets/img/github.svg';
 
export function StickyNavbar() {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
 
  const navList = (
    <ul className="overflow-clip mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="h4"
        color="white"
        className="p-1 font-normal"
      >
        <NavLink to="/" className="flex items-center">
          About
        </NavLink>
      </Typography>
			<Typography
        as="li"
        variant="h4"
        color="white"
        className="p-1 font-normal"
      >
        <NavLink to="/skills" className="flex items-center">
          Skills
        </NavLink>
      </Typography>
      <Typography
        as="li"
        variant="h4"
        color="white"
        className="p-1 font-normal"
      >
        <NavLink to="/projects" className="flex items-center">
          Projects
        </NavLink>
      </Typography>
    </ul>
  );

  return (
    <Navbar color="gray" className="sticky top-0 z-10 h-max max-w-full bg-backgroundTest-50 opacity-[0.99] rounded-none py-2 px-4 lg:px-8 lg:py-4">
      <div className="flex items-center justify-between">
        <Typography
          as="li"
          variant="h2"
          color="white"
          className="mr-4 cursor-pointer py-1.5 font-medium"
        >
          <NavLink to="/" className="flex items-center">
            Daniel Moxom
          </NavLink>
        </Typography>
        <div className="flex items-center gap-4">
          <div className="mr-4 hidden lg:block">{navList}</div>
          <a href="https://www.linkedin.com/in/daniel-moxom" target="_blank" rel="noreferrer">
            <Button variant="gradient" color="white" size="sm" className="hidden lg:inline-block rounded-full items-center gap-3 py-0 px-2.5">
              <img src={linkedin} alt="" className="h-12 w-7"/>
            </Button>
          </a>
          <a href="https://github.com/DMoxom" target="_blank" rel="noreferrer">
            <Button variant="gradient" color="white" size="sm" className="hidden lg:inline-block rounded-full items-center gap-3 py-0 px-2">
              <img src={github} alt="" className="h-12 w-8"/>
            </Button>
          </a>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
      <MobileNav open={openNav}>
        {navList}
        <a href="https://www.linkedin.com/in/daniel-moxom" target="_blank" rel="noreferrer">
          <Button variant="gradient" color="white" size="sm" className="rounded-full items-center gap-3 py-0 px-2.5 mb-3 mr-3">
            <img src={linkedin} alt="" className="h-12 w-7"/>
          </Button>
        </a>
        <a href="https://github.com/DMoxom" target="_blank" rel="noreferrer">
          <Button variant="gradient" color="white" size="sm" className="rounded-full items-center gap-3 py-0 px-2 mb-3 mr-3">
            <img src={github} alt="" className="h-12 w-8"/>
          </Button>
        </a>
      </MobileNav>
    </Navbar>
  );
}